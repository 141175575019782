import { makeStyles, Persona, shorthands, Text, tokens } from '@fluentui/react-components';
import { FC } from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setActiveRightHandPanel } from '../../../redux/features/app/appSlice';
import { FeatureKeys, RightHandPanel } from '../../../redux/features/app/AppState';
import { SharedStyles } from '../../../styles';
import { PersonaOptionsMenu } from '../persona/PersonaOptionsMenu';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        //...Breakpoints.small({
        //    justifyContent: 'center',
        //}),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
        },
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    rootSmall: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        cursor: 'pointer',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        //...Breakpoints.small({
        //    display: 'none',
        //}),
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    //popoverSurface: {
    //    display: 'none',
    //    ...Breakpoints.small({
    //        display: 'flex',
    //        flexDirection: 'column',
    //    }),
    //},
    selected: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    hide: {
        display: 'none',
    },
});

interface IPersonaListItemProps {
    id: string;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
}

export const PersonaListItem: FC<IPersonaListItemProps> = ({
    id,
    isConversationPaneFill,
    closeConversationPanel,
}) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();
    const features = useAppSelector((state: RootState) => state.app.features);

    const name: string = useAppSelector((state: RootState) => state.personas.personas[id].name);
    const instructions: string = useAppSelector(
        (state: RootState) => state.personas.personas[id].instructions
    );
    const personaPicture = useAppSelector(
        (state: RootState) => state.personas.personas[id].picture,
    );

    const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && instructions;

    const onClick = (_ev: any) => {
        void chat.createChat(id);
        dispatch(setActiveRightHandPanel(RightHandPanel.Chat));

        if (isConversationPaneFill) {
            closeConversationPanel();
        }
    };

    return (
        <PersonaOptionsMenu
            personaId={id}
            rightClickToOpen={true}
            TriggerComponent={
                <div
                    className={classes.root}
                    onClick={onClick}
                    title={`Persona: ${name}`}
                    aria-label={`Persona list item: ${name}`}
                >
                    <Persona
                        avatar={{
                            image: { src: personaPicture },
                        }}
                    />
                    
                    <div className={classes.body}>
                        <div className={classes.header}>
                            <Text className={classes.title} title={name}>
                                {name}
                            </Text>
                        </div>
                        {showPreview && (
                            <>
                                {
                                    <Text id={`persona-preview-${id}`} size={200} className={classes.previewText}>
                                        {instructions}
                                    </Text>
                                }
                            </>
                        )}
                    </div>
                </div>
            }
        />
    );
};
