import { Button } from '@fluentui/react-button';
import { Body1, Field, Input, Textarea, makeStyles, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { AddRegular } from '@fluentui/react-icons';
import { FormEvent, useState } from 'react';
import { usePersonas } from '../../../../libs/hooks/usePersonas';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    actions: {
        paddingTop: '10%',
    },
    createButton: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        width: 'fit-content',
    },
    textareaInput: {
        height: '100px',
    },
    error: {
        color: '#d13438',
    },
});

interface ICreatePersonaDialogProps {
    openMyPersonaTabHandler: () => void;
}

export const CreatePersonaDialog: React.FC<ICreatePersonaDialogProps> = ({
    openMyPersonaTabHandler
}) => {
    const classes = useClasses();
    const personas = usePersonas();

    const [personaName, setPersonaName] = useState('');
    const [personaInstructions, setPersonaInstructions] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [open, setOpen] = useState(false);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        void personas.createPersona(personaName, personaInstructions)
            .then(() => {
                openMyPersonaTabHandler();
                setOpen(false);
                setErrorMessage('');
                setPersonaName('');
                setPersonaInstructions('');
            })
            .catch(() => { 
                setErrorMessage('Failed to create persona. Please try again later.');
            });
    };

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogTrigger>
                <Button
                    onClick={() => { }}
                    appearance="primary"
                    className={classes.createButton}
                    icon={<AddRegular />}>
                    Create
                </Button>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Create A New Persona:</DialogTitle>
                        <DialogContent>
                            {errorMessage && <Body1 className={classes.error}>{errorMessage}</Body1>}
                            <Field label="Persona Name (Reference Only)">
                                <Input
                                    required
                                    type="text"
                                    value={personaName}
                                    onChange={(_e, input) => {
                                        setPersonaName(input.value);
                                    }}
                                />
                            </Field>
                            <Field label="Instructions">
                                <Textarea
                                    required
                                    textarea={{ className: classes.textareaInput }}
                                    resize='vertical'
                                    value={personaInstructions}
                                    onChange={(_e, input) => {
                                        setPersonaInstructions(input.value);
                                    }}
                                    placeholder='Customize the way the AI responds to you...'
                                />
                            </Field>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};
