import { PersonaType } from "../../../libs/models/Persona";

export interface PersonaState {
    personas: Personas;
    personaTypesLoaded: PersonaType[];
    pinnedPersonas: string[];
}

export const initialState: PersonaState = {
    personas: {},
    personaTypesLoaded: [],
    pinnedPersonas: [],
};

export type Personas = Record<string, PersonaData>;

export interface PersonaData {
    id: string;
    creatorUserId: string;
    name: string;
    instructions: string;
    lastUpdatedDate: number;
    type: PersonaType;
    isPinned: boolean;
    picture: string;
}

export interface PersonaChange {
    id: string;
    name: string;
    instructions: string;
}