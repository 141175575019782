import { Button } from '@fluentui/react-button';
import { Field, Input, Textarea, makeStyles, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { store } from '../../../../redux/app/store';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    actions: {
        paddingTop: '10%',
    },
    createButton: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        width: 'fit-content',
    },
    textareaInput: {
        height: '100px',
    },
    error: {
        color: '#d13438',
    },
});

interface IViewPersonaDialogProps {
    personaId: string;
}

export interface ViewPersonaDialogRef {
    openDialog: () => void;
}

export const ViewPersonaDialog = forwardRef<ViewPersonaDialogRef, IViewPersonaDialogProps>(({ personaId }, ref) => {
    const classes = useClasses();

    const [personaName, setPersonaName] = useState('');
    const [personaInstructions, setPersonaInstructions] = useState('');
    const [open, setOpen] = useState(false);

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load persona data from redux store
            setPersonaName(store.getState().personas.personas[personaId].name);
            setPersonaInstructions(store.getState().personas.personas[personaId].instructions);
            
            setOpen(true);
        }
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>View Persona:</DialogTitle>
                    <DialogContent>
                        <Field label="Persona Name">
                            <Input
                                disabled={true}
                                required
                                type="text"
                                value={personaName}
                            />
                        </Field>
                        <Field label="Instructions">
                            <Textarea
                                disabled={true}
                                required
                                textarea={{ className: classes.textareaInput }}
                                resize='vertical'
                                value={personaInstructions}
                            />
                        </Field>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

ViewPersonaDialog.displayName = 'ViewPersonaDialog';