import {
    Divider,
    Radio,
    RadioGroup,
    RadioGroupProps,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { useUserSettings } from '../../../libs/hooks';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys, Setting } from '../../../redux/features/app/AppState';

const useClasses = makeStyles({
    feature: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalNone),
    },
    featureDescription: {
        paddingLeft: '5%',
        paddingBottom: tokens.spacingVerticalS,
    },
});

interface ISettingSectionRadioButtonsProps {
    setting: Setting;
    contentOnly?: boolean;
}

export const SettingSectionRadioButtons: React.FC<ISettingSectionRadioButtonsProps & RadioGroupProps> = ({
    setting,
    contentOnly,
    ...props
}) => {
    const classes = useClasses();
    const { features } = useAppSelector((state: RootState) => state.app);
    const userSettings = useUserSettings();

    const handleSelectionChange = (featureKey: FeatureKeys) => {
        for (const feature of setting.features) {
            if (feature == featureKey) {
                void userSettings.enableUserSetting(feature);
            } else {
                void userSettings.disableUserSetting(feature);
            }
        }
    };

    return (
        <>
            {!contentOnly && <h3>{setting.title}</h3>}
            {setting.description && <p>{setting.description}</p>}
            <div
                style={{
                    display: 'flex',
                    flexDirection: `${setting.stackVertically ? 'column' : 'row'}`,
                    flexWrap: 'wrap',
                }}
            >
                <RadioGroup
                    {...props}
                    style={{
                        display: 'flex',
                        flexDirection: `${setting.stackVertically ? 'column' : 'row'}`,
                        flexWrap: 'wrap',
                    }}
                >
                    {setting.features.map((key) => {
                        const feature = features[key];
                        return (
                            <div key={key} className={classes.feature}>
                                <Radio
                                    label={feature.label}
                                    value={feature.enabled.toString()}
                                    checked={feature.enabled}
                                    onChange={() => {
                                        handleSelectionChange(key);
                                    }}
                                />
                            </div>
                        );
                    })}
                </RadioGroup>
            </div>
            {!contentOnly && <Divider />}
        </>
    );
};
