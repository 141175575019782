import { Button } from '@fluentui/react-button';
import { Body1, Field, Input, Textarea, makeStyles, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { FormEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { usePersonas } from '../../../../libs/hooks/usePersonas';
import { store } from '../../../../redux/app/store';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    actions: {
        paddingTop: '10%',
    },
    createButton: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        width: 'fit-content',
    },
    textareaInput: {
        height: '100px',
    },
    error: {
        color: '#d13438',
    },
});

interface IEditPersonaDialogProps {
    personaId: string;
}

export interface EditPersonaDialogRef {
    openDialog: () => void;
}

export const EditPersonaDialog = forwardRef<EditPersonaDialogRef, IEditPersonaDialogProps>(({ personaId }, ref) => {
    const classes = useClasses();
    const personas = usePersonas();

    const [personaName, setPersonaName] = useState('');
    const [personaInstructions, setPersonaInstructions] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        void personas.editPersona(personaId, personaName, personaInstructions)
            .then(() => {
                setOpen(false);
                setErrorMessage('');
            })
            .catch(() => { 
                setErrorMessage('Failed to create persona. Please try again later.');
            });
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load persona data from redux store
            setPersonaName(store.getState().personas.personas[personaId].name);
            setPersonaInstructions(store.getState().personas.personas[personaId].instructions);

            setOpen(true);
        }
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Edit Persona:</DialogTitle>
                        <DialogContent>
                            {errorMessage && <Body1 className={classes.error}>{errorMessage}</Body1>}
                            <Field label="Persona Name (Reference Only)">
                                <Input
                                    required
                                    type="text"
                                    value={personaName}
                                    onChange={(_e, input) => {
                                        setPersonaName(input.value);
                                    }}
                                />
                            </Field>
                            <Field label="Instructions">
                                <Textarea
                                    required
                                    textarea={{ className: classes.textareaInput }}
                                    resize='vertical'
                                    value={personaInstructions}
                                    onChange={(_e, input) => {
                                        setPersonaInstructions(input.value);
                                    }}
                                    placeholder='Customize the way the AI responds to you...'
                                />
                            </Field>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
});

EditPersonaDialog.displayName = 'EditPersonaDialog';