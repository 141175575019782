import { FC } from 'react';

import { Button, Tooltip } from '@fluentui/react-components';
import { BotAdd20Regular } from '@fluentui/react-icons';
import { useChat } from '../../../../libs/hooks';
import { useAppDispatch } from '../../../../redux/app/hooks';
import { RightHandPanel } from '../../../../redux/features/app/AppState';
import { setActiveRightHandPanel } from '../../../../redux/features/app/appSlice';

export const NewChat: FC = () => {
    const chat = useChat();
    const dispatch = useAppDispatch();

    const onAddChat = () => {
        void chat.createChat();
        dispatch(setActiveRightHandPanel(RightHandPanel.Chat));
    };

    return (
        <div>
            <Tooltip content="Create new conversation" relationship="label">
                <Button icon={<BotAdd20Regular />} appearance="transparent" onClick={onAddChat} />
            </Tooltip>
        </div>
    );
};
