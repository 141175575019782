import { FeatureKeys } from '../../redux/features/app/AppState';
import { IUserSetting } from '../models/UserSetting';
import { BaseService } from './BaseService';

export default class UserSettingsService extends BaseService {
    public saveUserSettings = async (settings: FeatureKeys, accessToken: string): Promise<boolean> => {
        const result = await this.getResponseAsync<boolean>(
            {
                commandPath: `/userSettings/save/${settings}`,
                method: 'POST',
            },
            accessToken,
        );
        return result;
    };

    public saveUserSettingsDisable = async (settings: FeatureKeys, accessToken: string): Promise<boolean> => {
        const result = await this.getResponseAsync<boolean>(
            {
                commandPath: `/userSettings/saveDisable/${settings}`,
                method: 'POST',
            },
            accessToken,
        );
        return result;
    };

    public saveUserSettingsEnable = async (settings: FeatureKeys, accessToken: string): Promise<boolean> => {
        const result = await this.getResponseAsync<boolean>(
            {
                commandPath: `/userSettings/saveEnable/${settings}`,
                method: 'POST',
            },
            accessToken,
        );
        return result;
    };

    public getUserSettings = async (accessToken: string): Promise<IUserSetting[]> => {
        const result = await this.getResponseAsync<IUserSetting[]>(
            {
                commandPath: `/userSettings/get`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
}
